import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import CustomButton from '../../common/CustomButton/CustomButton';
import CustomForm from '../../common/CustomForm/CustomForm';
import CustomInput from '../../common/CustomInput/CustomInput';
import Message from '../../common/Message/Message';
import PageTitle from '../../common/PageTitle/PageTitle';
import { useAlert } from '../../core/context/alert.context';
import { useDataContext } from '../../core/context/data.context';
import { isValidEmail } from '../../core/helpers';
import useFetch from '../../core/hooks/useFetch';
import { useFormInput } from '../../core/hooks/useFormInput';
import './ForgotPassword.css';

const BASE_URL = process.env.REACT_APP_API_URL;

function ForgotPassword() {
  const { setAlert } = useAlert();
  const email = useFormInput('', (value) => isValidEmail(value));
  const { result, doFetch, loading, error } = useFetch(`${BASE_URL}/user/send-reset-password-link`);
  const {
    data: { generalData },
  } = useDataContext();

  const handleSubmit = useCallback(() => {
    doFetch({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email.value,
      }),
    });
  }, [doFetch, email.value]);

  useEffect(() => {
    if (result?.status !== 'success') return;
    setAlert({
      message: result.message,
    });
  }, [result, setAlert]);

  return (
    <>
      <Helmet>
        {!generalData?.seo?.title ? null : <title>{generalData?.seo?.title}</title>}
        {!generalData?.seo?.description ? null : (
          <meta name="description" content={generalData?.seo?.description} />
        )}
      </Helmet>
      <section className="forgot-password">
        <div className="wrap wrap--xs">
          <PageTitle
            title="Reset password"
            backLabel="Back to login page"
            backPath={-1}
            info="Enter your email and we will send you a password reset link"
          />
          <CustomForm className="flex gap-sm" inputs={[email]} onSubmit={handleSubmit}>
            <CustomInput
              id="email"
              type="text"
              name="email"
              placeholder="Enter your email address"
              label="Email address"
              input={email}
              emptyMessage="Please fill in your email"
              invalidMessage="Plese fill in a valid email address"
            />
            <CustomButton className="login-form__cta btn btn--dark" type="submit" loading={loading}>
              Send reset password link
            </CustomButton>
            {!error ? null : <Message text={error.message} status="error" />}
          </CustomForm>
        </div>
      </section>
    </>
  );
}

export default ForgotPassword;
