import { forwardRef } from 'react';

interface IProps {
  type: 'submit' | 'button';
  className: string;
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?(): void;
  id?: string;
}

const CustomButton = forwardRef<HTMLButtonElement, IProps>(
  ({ type, loading, disabled, className, children, onClick, id }, ref) => (
    <button
      className={className}
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={onClick}
      disabled={loading || disabled}
      ref={ref}
      id={id}
    >
      {children}
      {loading && <span className="loader">Loading...</span>}
    </button>
  ),
);

export default CustomButton;
