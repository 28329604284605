import { useCallback, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';

import mediaGrey from '../../assets/contact-grey.mp4';
import media from '../../assets/contact.mp4';
import { Icon } from '../../common/Icon/Icon';
import useModal from '../../core/hooks/useModal';
import { IContact } from '../../core/models';
import HubSpotForm from '../HubSpotForm/HubSpotForm';
import './Contact.css';

function Contact({
  title,
  desc,
  btnLabel,
  portalId,
  formId,
  region,
  id,
  background,
  slug,
}: IContact) {
  const { modal, open, close } = useModal();
  const btnRef = useRef(null);

  const openModal = useCallback(() => {
    if (!btnRef.current) return;
    open(btnRef.current);
  }, [open]);

  return (
    <section
      className={`contact${background === 'grey' ? ' section-bg section-bg--grey' : ' section'}`}
    >
      <div className="wrap">
        <div className="contact__container px-md py-md gap-lg">
          <div className="contact__media-container">
            <video
              className="contact__media"
              src={background === 'grey' ? media : mediaGrey}
              muted
              autoPlay
              aria-hidden
              playsInline
            />
          </div>
          <div className="contact__content flex gap-lg">
            <h2 className="contact__title h2">{title}</h2>
            {!desc ? null : (
              <ReactMarkdown
                className="contact__desc body-large"
                rehypePlugins={[
                  [rehypeExternalLinks, { target: '_blank', rel: ['noopener', 'noreferrer'] }],
                ]}
              >
                {desc}
              </ReactMarkdown>
            )}
            <button
              className="contact__cta btn btn--secondary"
              type="button"
              ref={btnRef}
              onClick={openModal}
              id={`${slug}-${formId}`}
            >
              {btnLabel}
            </button>
          </div>
        </div>
      </div>
      {modal({
        children: (
          <>
            <button className="modal__close" onClick={close} type="button">
              <Icon.Plus className="icon-large" />
              <span className="sr-only">Close modal</span>
            </button>
            {!portalId || !formId || !region || !id ? null : (
              <HubSpotForm portalId={portalId} formId={formId} region={region} id={id} />
            )}
          </>
        ),
        keepMounted: true,
      })}
    </section>
  );
}

export default Contact;
