import OpenAI from 'openai';
import { TextContentBlock } from 'openai/resources/beta/threads/messages';
import { useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomForm from '../../../common/CustomForm/CustomForm';
import CustomInput from '../../../common/CustomInput/CustomInput';
import { Icon } from '../../../common/Icon/Icon';
import { isEmpty, isJsonString } from '../../../core/helpers';
import { useFormInput } from '../../../core/hooks/useFormInput';
import { getCookie, setCookie } from '../../../core/service';
import './Chat.css';

const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

interface IProps {
  title?: string;
  assistantID: string;
}

interface IMessage {
  id: string;
  text: string;
  role: 'user' | 'assistant';
  created_at: string;
}

interface ICookie {
  id: string;
  label: string;
}

function Chat({ title, assistantID }: IProps) {
  const input = useFormInput('', (value) => !isEmpty(value));
  const [openAI, setOpenAI] = useState<OpenAI | null>(null);
  const [threads, setThreads] = useState<ICookie[]>([]);
  const [thread, setThread] = useState<string | null>(null);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const [showThreads, setShowThreads] = useState(false);

  const updateLabel = useCallback(
    (label: string, id: string) => {
      const cookieThreads = getCookie(`openAIThread-${assistantID}`);
      if (!isJsonString(cookieThreads)) return;

      const cookieThreadsArr: ICookie[] = JSON.parse(cookieThreads);
      const newCookieThreadsArr: ICookie[] = cookieThreadsArr.map((el) =>
        el.id === id ? { ...el, label } : el,
      );
      setCookie(`openAIThread-${assistantID}`, JSON.stringify(newCookieThreadsArr));
      setThreads(newCookieThreadsArr);
    },
    [assistantID],
  );

  const printMessages = useCallback(
    async (threadId: string, AI: OpenAI) => {
      if (!AI) return;

      const newMessages:
        | OpenAI.Beta.Threads.Messages.MessagesPage
        | OpenAI.Beta.Threads.Messages.Message = await AI.beta.threads.messages.list(threadId);
      setLoading(false);

      setMessages(() =>
        newMessages.data.map((message) => ({
          id: message.id,
          text: (message.content[0] as TextContentBlock).text.value,
          role: message.role,
          created_at: new Date(message.created_at * 1000).toLocaleString(),
        })),
      );
      updateLabel(
        (newMessages.data[0]?.content[0] as TextContentBlock)?.text.value || 'New thread',
        threadId,
      );
    },
    [updateLabel],
  );

  const createNewThread = useCallback(
    async (newOpenAI: OpenAI, prevThreads: { id: string; label: string }[]) => {
      setLoading(true);
      const newThread: OpenAI.Beta.Threads.Thread = await newOpenAI.beta.threads.create();
      const newThreadObj = { id: newThread.id, label: 'New thread' };
      setCookie(`openAIThread-${assistantID}`, JSON.stringify([...prevThreads, newThreadObj]));
      setThread(newThread.id);
      setThreads(() => [...prevThreads, newThreadObj]);
      setLoading(false);
    },
    [assistantID],
  );

  useEffect(() => {
    if (!assistantID || !apiKey) return;

    const newOpenAI: OpenAI = new OpenAI({
      apiKey,
      dangerouslyAllowBrowser: true,
    });
    setOpenAI(newOpenAI);

    const initThread = () => {
      const cookieThreads = getCookie(`openAIThread-${assistantID}`);

      if (!isJsonString(cookieThreads)) {
        createNewThread(newOpenAI, []);
      } else {
        const cookieThreadsArr: ICookie[] = JSON.parse(cookieThreads);

        if (cookieThreadsArr.length) {
          setThreads(cookieThreadsArr);
          setThread(cookieThreadsArr[cookieThreadsArr.length - 1].id);
          setLoading(true);
          printMessages(cookieThreadsArr[cookieThreadsArr.length - 1].id, newOpenAI);
        } else {
          createNewThread(newOpenAI, []);
        }
      }
    };
    initThread();
  }, [assistantID, createNewThread, printMessages]);

  useEffect(() => {
    if (!thread || !openAI) return;
    printMessages(thread, openAI);
  }, [thread, openAI, printMessages]);

  const submit = useCallback(async () => {
    if (!openAI || !thread || !assistantID) return;
    setLoading(true);
    await openAI.beta.threads.messages.create(thread, {
      role: 'user',
      content: input.value,
    });
    input.setValue('');

    await openAI.beta.threads.runs.createAndPoll(thread, {
      assistant_id: assistantID,
    });

    printMessages(thread, openAI);
  }, [assistantID, input, openAI, printMessages, thread]);

  const deleteThread = useCallback(
    (id: string) => {
      setThreads((prevState) => {
        const filteredThreads = prevState.filter((el) => el.id !== id);
        setThread(filteredThreads[0]?.id || null);
        setCookie(`openAIThread-${assistantID}`, JSON.stringify(filteredThreads));
        return filteredThreads;
      });
    },
    [assistantID],
  );

  return (
    <div className="chat">
      {!openAI || !assistantID ? null : (
        <div className="chat__container">
          {!title ? null : <h2 className="h3">{title}</h2>}
          <div className={`chat__content${showThreads ? ' open' : ''}`}>
            <div className="chat__threads">
              <CustomButton
                className="chat__thread chat__thread--new"
                type="button"
                onClick={() => createNewThread(openAI, threads)}
                disabled={!showThreads}
              >
                Create new thread
                <Icon.Plus />
              </CustomButton>
              {threads.map((el) => (
                <div className="chat__thread-container" key={el.id}>
                  <CustomButton
                    className={`chat__thread${el.id === thread ? ' active' : ''}`}
                    type="button"
                    onClick={() => {
                      setLoading(true);
                      setThread(el.id);
                    }}
                    disabled={!showThreads}
                  >
                    <ReactMarkdown className="truncate l-2">{el.label}</ReactMarkdown>
                  </CustomButton>
                  <CustomButton
                    className="chat__thread-delete"
                    type="button"
                    onClick={() => deleteThread(el.id)}
                    disabled={!showThreads}
                  >
                    <Icon.Bin />
                    <span className="sr-only">delete thread {el.label}</span>
                  </CustomButton>
                </div>
              ))}
            </div>
            <div className="chat__chat">
              <CustomButton
                className="chat__thread-toggle"
                type="button"
                onClick={() => setShowThreads(!showThreads)}
              >
                <Icon.Chevron />
                <span className="sr-only">{showThreads ? 'hide threads' : 'show threads'}</span>
              </CustomButton>
              <div className="chat__messages-container">
                <div className={`chat__messages${loading ? ' loading' : ''}`}>
                  {messages?.map((message) => (
                    <div
                      className={`chat__message-container${
                        message.role === 'user' ? ' chat__message-container--user' : ''
                      }`}
                      key={message.id}
                    >
                      <ReactMarkdown className="chat__message">{message.text}</ReactMarkdown>
                      <span className="chat__message-date">{message.created_at}</span>
                    </div>
                  ))}
                </div>
              </div>
              <CustomForm className="chat__form" inputs={[input]} onSubmit={submit}>
                <CustomInput
                  input={input}
                  id={`chat-${assistantID}`}
                  type="text"
                  name="chat"
                  placeholder="Chat"
                  emptyMessage="Please add a message"
                  autoComplete="off"
                />
                <CustomButton className="btn btn--secondary" type="submit" disabled={loading}>
                  Submit
                </CustomButton>
              </CustomForm>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Chat;
