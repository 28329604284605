import React, { useCallback, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

import CustomLink from '../../../common/CustomLink/CustomLink';
import { Icon } from '../../../common/Icon/Icon';
import useModal from '../../../core/hooks/useModal';
import { ILink } from '../../../core/models';
import HubSpotForm from '../../HubSpotForm/HubSpotForm';

interface IProps {
  title: string;
  desc?: string;
  link?: ILink;
  form: {
    portalId?: string;
    formId?: string;
    region?: string;
    id?: string;
    btnLabel?: string;
  };
  slug: string;
}

function ProgramsItem({ title, desc, link, form, slug }: IProps) {
  const { modal, open, close } = useModal();
  const btnRef = useRef(null);

  const openModal = useCallback(() => {
    if (!btnRef.current) return;
    open(btnRef.current);
  }, [open]);

  return (
    <div className="programs__item py-md px-md" key={title}>
      <div className="programs__item-container flex gap-lg">
        <div className="programs__item-content flex gap-md">
          <h3 className="programs__item-title h2">
            <ReactMarkdown
              components={{
                p: React.Fragment,
                em: 'u',
              }}
            >
              {title}
            </ReactMarkdown>
          </h3>
          {!desc ? null : (
            <ReactMarkdown
              className="programs__item-desc body-large"
              components={{
                em: 'span',
              }}
            >
              {desc}
            </ReactMarkdown>
          )}
        </div>
        <div className="programs__item-footer gap-sm">
          {!form.btnLabel || !form.portalId || !form.formId || !form.region || !form.id ? null : (
            <>
              <button
                className="programs__item-cta btn btn--secondary"
                type="button"
                onClick={openModal}
                ref={btnRef}
                id={`${slug}-${form.formId}`}
              >
                {form.btnLabel}
              </button>
              {modal({
                children: (
                  <>
                    <button className="modal__close" onClick={close} type="button">
                      <Icon.Plus className="icon-large" />
                      <span className="sr-only">Close modal</span>
                    </button>
                    <HubSpotForm
                      portalId={form.portalId}
                      formId={form.formId}
                      region={form.region}
                      id={form.id}
                    />
                  </>
                ),
                keepMounted: true,
              })}
            </>
          )}
          {!link ? null : <CustomLink className="programs__item-cta" link={link} />}
        </div>
      </div>
    </div>
  );
}

export default ProgramsItem;
