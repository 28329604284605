import { Helmet } from 'react-helmet-async';

import SecurityForm from '../../../Components/User/SecurityForm/SecurityForm';
import PageTitle from '../../../common/PageTitle/PageTitle';
import { useDataContext } from '../../../core/context/data.context';

function SecurityPrivacy() {
  const {
    data: { generalData },
  } = useDataContext();

  return (
    <>
      <Helmet>
        {!generalData?.seo?.title ? null : <title>{generalData?.seo?.title}</title>}
        {!generalData?.seo?.description ? null : (
          <meta name="description" content={generalData?.seo?.description} />
        )}
      </Helmet>
      <section className="security-privacy">
        <div className="wrap wrap--xs">
          <PageTitle title="Security & privacy" backLabel="Back" backPath="/user" />
        </div>
        <div className="wrap wrap--xs">
          <SecurityForm />
        </div>
      </section>
    </>
  );
}

export default SecurityPrivacy;
